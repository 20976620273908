@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Roboto",'Roboto Slab', "Courier New", "SukhumvitSet";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;

  /** background image */
  /* background-image: url("./assets/Background/landing_page_desktop.svg");
  background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  /* background-position: 100% 0; */
  font-display: swap;
  /* background-size:50%; */
}
/* @media only screen and (max-width: 768px) {
  body {
  background-image: url("./assets/Background/landing_page_mobile.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  height: '58.8 rem';
  font-display: swap;
  }
} */
@font-face {
  font-family: 'SukhumvitSet-Light';
  src: local('SukhumvitSet-Light'), url(/static/media/sukhumvit_light.6cbb196c.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'SukhumvitSet-Bold';
  src: local('SukhumvitSet-Bold'), url(/static/media/sukhumvit_bold.6770de5f.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'SukhumvitSet-Medium';
  src: local('SukhumvitSet-Medium'), url(/static/media/sukhumvit_medium.f15690bd.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'SukhumvitSet-Semibold';
  src: local('SukhumvitSet-Semibold'), url(/static/media/sukhumvit_semibold.f10f61b5.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'SukhumvitSet-Text';
  src: local('SukhumvitSet-Text'), url(/static/media/sukhumvit_text.f48143e3.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Sarabun';
  src: local('Sarabun'), url(/static/media/sarabun_regular.9cef964b.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Sarabun-Semibold';
  src: local('Sarabun-Semibold'), url(/static/media/sarabun_semiBold.6913629c.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Sarabun-thin';
  src: local('Sarabun-thin'), url(/static/media/sarabun_thin.abdfce5e.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Sarabun-Regular';
  src: local('Sarabun-Regular'), url(/static/media/sarabun_regular.9cef964b.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Sarabun-Light';
  src: local('Sarabun-Light'), url(/static/media/Sarabun-Light.3c1a731b.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Sarabun-Medium';
  src: local('Sarabun-Medium'), url(/static/media/Sarabun-Medium.d497a8b3.ttf) format('truetype');
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Roboto"
    monospace;
}
